<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- About Area Component -->
  <about-area/>
  <!-- Counter Area Component -->
<!--  <counter-area/>-->
  <!-- Expert Area Component -->
<!--  <expert-area/>-->
</div>
</template>

<script>
import BreadcrumbArea from "../components/BreadcrumbArea";
import AboutArea from "../components/AboutArea";
// import CounterArea from "../components/CounterArea";
// import ExpertArea from "../components/ExpertArea";
export default {
  name: "About",
  components: {
    // ExpertArea,
    // CounterArea,
    AboutArea,
    BreadcrumbArea
  },
  data() {
    return {
      breadcrumb: {
        page: "About",
        pageTitle: "about us"
      }
    }
  }
}
</script>

<style scoped>

</style>