<template>
  <!-- About Page Area Start -->
  <section class="ekifa-about-page-area section_80">
    <b-container>
      <b-row>
        <b-col md="5">
          <div class="about-page-left">
            <a class="popup-youtube" href="https://www.youtube.com/watch?v=k-R6AFn9-ek">
              <i class="fa fa-play"></i>
              Watch overview
            </a>
            <img :src="require('../assets/img/about.jpg')" alt="associates about page">
          </div>
        </b-col>
        <b-col lg="7">
          <div class="about-page-right">
            <h3>Overview of the firm's<br><span>history,</span> mission, <span>values</span></h3>
            <p>Pellentesque bibendum. Cras porttitor. Don cursus ante et vulputate feugiat mil justo faucibusn sd Integad elemen tuma volutpat vestibulum enim mi tincidunt. Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incidanaut labore et dolore.dipisicing elit sed do eiusmod tempor incidanaut labore et</p>
            <p>congue elit non semper laoreet sed lectus orcil posuer nisl tempor se felis pelentesque inyd urna. Integer vitae felis vel magna posu dul vestibulum. Nam rutrumc diam. Aliquam males uada maurs etulg metu Curabitur laoreet convallis nisal.</p>
            <div class="about-page-right-listing">
              <ul>
                <li><i class="fa fa-angle-double-right"></i> Credible</li>
                <li><i class="fa fa-angle-double-right"></i> Professional Lawyers</li>
                <li><i class="fa fa-angle-double-right"></i> The Best in Lebanon</li>
              </ul>
              <ul>
                <li><i class="fa fa-angle-double-right"></i> Great planning</li>
                <li><i class="fa fa-angle-double-right"></i> Best Support</li>
                <li><i class="fa fa-angle-double-right"></i> Reliable</li>
              </ul>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore atque officiis maxime suscipit expedita obcaecati nulla in ducimus iure</p>
            <b-link class="ekifa-btn">Read More</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- About Page Area End -->
</template>

<script>
export default {
  name: "AboutArea"
}
</script>

<style scoped>

</style>